import tw from "twin.macro";
import styled from "styled-components";

export const CustomSubtitle = styled.p`${tw`text-center 
xxs:text-lg xs:text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-3xl
px-10 xxs:px-0 text-center 
py-0 xxs:py-5
text-text-color 
mx-auto `}
font-family: 'Ultra';
font-style: normal;
line-height: 22px`;


export const CustomSubtitleBlock = styled.p`${tw`text-center 
xxs:text-lg xs:text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-3xl 2xl:text-3xl
px-10 xxs:px-0 text-center 
py-0 xxs:py-5
text-text-color 
mx-auto `}
font-family: 'Ultra';
font-style: normal;
line-height: 22px`;


// export const CustomSubtitleBlock = styled.p`${tw`text-center 
// xxs:text-lg xs:text-xl sm:text-xl md:text-xl lg:text-2xl xl:text-2xl 2xl:text-2xl
// px-10 xxs:px-0 text-center 
// py-0 xxs:py-5 xs:py-10 sm:py-10 md:py-10 lg:py-10 xl:py-10 2xl:py-10
// text-text-color 
// mx-auto `}
// font-family: 'Ultra';
// font-style: normal;
// line-height: 22px`;


export const CustomParagraphNoteBlock = styled.p`${tw`text-center 
text-sm xxs:text-sm xs:text-sm sm:text-xs md:text-xs lg:text-sm xl:text-sm 2xl:text-sm
text-text-color 
mx-auto
`}
font-family: 'Amiko';
font-style: normal;
line-height: 32px`;



export const CustomParagraph = styled.p`${tw`text-center 
text-xl xxs:text-lg xs:text-xl sm:text-xl md:text-2xl lg:text-xl xl:text-2xl 2xl:text-2xl pt-10
text-text-color 
mx-auto
`}
font-family: 'Amiko';
font-style: normal;
line-height: 32px`;


export const CustomParagraphWithPX = styled.p`${tw`text-center 
text-xl xxs:text-lg xs:text-xl sm:text-xl md:text-2xl lg:text-xl xl:text-2xl 2xl:text-2xl
text-text-color 
px-56 xxs:px-5 xs:px-10 sm:px-20 md:px-20 lg:px-28 xl:px-28 2xl:px-35
`}
font-family: 'Amiko';
font-style: normal;
line-height: 32px`;


export const CustomParagraphBlock = styled.p`${tw`text-center 
text-xl xxs:text-lg xs:text-xl sm:text-xl md:text-2xl lg:text-xl xl:text-2xl 2xl:text-2xl
text-text-color 
mx-auto
`}
font-family: 'Amiko';
font-style: normal;
line-height: 32px`;

// export const CustomParagraphBlock = styled.p`${tw`text-center 
// text-xl xxs:text-lg xs:text-xl sm:text-xl md:text-sm lg:text-xl xl:text-xl 2xl:text-xl
// text-text-color 
// mx-auto
// `}
// font-family: 'Amiko';
// font-style: normal;
// line-height: 32px`;


export const CustomSpaceContainerXs = tw.div`
relative 
h-0 xxs:h-0 xs:h-2 sm:h-2 md:h-2 lg:h-5 2xl:h-10
`;

export const CustomSpaceContainer = tw.div`
relative 
// bg-blue-200
h-0 3xs:h-10 xxs:h-10 xs:h-10 sm:h-10 md:h-10 lg:h-12 2xl:h-12
`;

export const CustomSpaceContainerXl = tw.div`
relative 
// bg-blue-400
h-0 xxs:h-10 xs:h-16 sm:h-16 md:h-0 lg:h-10 2xl:h-20
`;

export const CustomSpaceContainer2Xl = tw.div`
relative 
// bg-red-600
h-0 xxs:h-12 xs:h-28 sm:h-20 md:h-20 lg:h-16 2xl:h-20
`;


export const CustomSpaceContainer3Xl = tw.div`
relative 
// bg-yellow-800
h-0 xxs:h-20 xs:h-28 sm:h-28 md:h-28 lg:h-28 2xl:h-35
`;

export const CustomSpaceContainerMobile = tw.div`
relative 
h-10 xxs:h-10 xs:h-10 sm:h-0 md:h-0 lg:h-0 2xl:h-0
`;

export const CustomTitle = styled.p`${tw`text-center 
xxs:text-3xl xs:text-4xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-5xl 2xl:text-6xl

xxs:py-0 xs:py-2 sm:py-3 md:py-3 lg:py-5 xl:py-5 2xl:py-10

px-10 xxs:px-0 text-center 
text-text-color 
mx-auto `}
font-family: 'Ultra';
font-style: normal;
line-height: 22px`;